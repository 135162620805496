<template>
  <div class="relative ">
    <div class="relative">
      <div class="absolute inset-0 bg-white bg-center"></div>
      <div class="absolute inset-0 bg-pattern bg-white bg-center opacity-40"></div>
      <div
        class="h-1/2 left-0 right-0 absolute bottom-0 bg-gradient-to-t to-transparent from-white"
      ></div>
      <section
        class="max-w-6xl mx-auto px-4 pt-16 md:pt-20 relative md:min-h-[600px]"
      >
        <div class="md:flex justify-center">
          <div class="flex-1">
            <!-- <h1
              class="text-gray-600 text-center mb-16 text-3xl font-bold h-56 md:h-44 lg:h-20"
              style="font-family: 'Jetbrains Mono';"
            >
              <div id="typewriter-header"></div>
            </h1> -->
            <img src="@/assets/NG-homepage.gif"/>
            <h2 class="uppercase italic text-xl mt-4 ml-4">
              And all the data you need to
              <span
                class="font-bold text-blue-700 block"
                id="typewriter-header"
              ></span>
            </h2>
          </div>
          <div class="flex-1">
            <div class="md:mt-0 mt-10" v-if="nextMatchTime">
              <p
                class="uppercase text-brandGrey mb-4 text-center"
                v-if="countdown"
              >
                Next game
                <template v-if="countdown != 'starting now!'"> in</template>
                {{ countdown }}
              </p>
              <Card
                mode="chrono"
                :match="nextMatch"
                v-if="nextMatch.date"
                class="md:w-1/3 mx-auto w-full mb-10"
              />
            </div>
            <div v-else class="mb-10 flex items-center justify-center">
              <Loader></Loader>
            </div>
            <div
              class="flex flex-col inset-0 items-center justify-center relative pb-10"
            >
              <div class="block space-x-4">
                <router-link
                  :to="`/signup/${type}`"
                  class="inline-flex bg-blue-700 items-center justify-center border border-blue-700 text-xs p-3 px-6 font-bold uppercase lg:text-lg text-white"
                >
                  Get Started
                </router-link>
                <!-- <router-link
              to="/why"
              class="inline-flex bg-white underline items-center justify-center border border-brandGrey text-xs p-3 px-6 font-bold uppercase lg:text-lg text-blue-700"
            >
              Watch Demo
            </router-link> -->
              </div>
              <div class="block mt-4">
                <button
                  class="text-xs uppercase text-brandGrey underline"
                  v-on:click="toggleLogin"
                >
                  Already have an account? Login here
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <template v-if="nextMatch.date">
      <div class="bg-white">
        <div class="">
          <section class="max-w-2xl mx-auto px-4 pb-16 relative">
            <img src="@/assets/join-the-movement.png" />
            <h2
              class="mt-4 uppercase text-black mb-2 drop-shadow text-lg text-center italic"
            >
              JOIN THE 100K+ PEOPLE USING THE FASTEST GROWING SPORTS PREDICTION
              PLATFORM IN THE WORLD
            </h2>
          </section>
        </div>
      </div>
      <div class="bg-white">
        <img
          src="@/assets/angled/1.svg"
          class="absolute w-full -translate-y-16"
        />
        <section class="max-w-6xl mx-auto px-4 py-16 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 mb-8 md:mb-0 md:text-right">
                <h2
                  class="uppercase text-gray-800 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                Gain access to our community 📲
                </h2>
                <p>
                  Join <strong>likeminded people</strong> to discuss upcoming matches, data and general football chat.
                  Get <strong>regular, exclusive tips</strong> from the team behind Numbers Game.
                </p>
              </div>
              <img src="@/assets/discord-icon.png" class="w-full h-full items-center justify-center md:w-1/5 md:h-1/5" alt="Numbers Game Discord community">
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <div class="">
          <section class="max-w-6xl mx-auto px-4 py-16 relative">
            <div class="md:flex items-center justify-center gap-10 md:gap-8">
              <div class="flex-1 mb-8 md:mb-0">
                <h2
                  class="uppercase text-gray-800 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Stop Guessing, Start <br />Predicting 🔮
                </h2>
                <p>
                  Our machine learning algorithms <strong>accurately predict</strong> matches across 
                  more than <strong>50 competitions</strong>, enabling you to make better informed
                  decisions in seconds.
                </p>
              </div>
              <div class="blur-type-1" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  v-if="nextMatch.date"
                  class="!w-full md:min-w-[385px]"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="bg-white">
        <section class="max-w-6xl mx-auto px-4 py-16 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-grid">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 mb-8 md:mb-0 md:text-right">
                <h2
                  class="uppercase text-gray-800 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Relevant data, at a glance 👀
                </h2>
                <p>
                  Make <strong>data-driven</strong> decisions by using the  <strong><span style="color:#1C4ED8">SEE DATA</span></strong> button to view
                  detailed player and team stats, head to head and recent form.
                </p>
              </div>
              <div class="blur-type-3" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  v-if="nextMatch.date"
                  class="!w-full md:min-w-[385px] relative"
                  :extraBlurContent="true"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white relative">
        <img
          src="@/assets/angled/2.svg"
          class="absolute w-full -translate-y-16"
        />

        <div class="">
          <section class="max-w-4xl mx-auto px-4 py-16 relative">
            <div class="md:flex items-center justify-center gap-10 md:gap-8">
              <vee-observe
                :root="null"
                :root-margin="'0px'"
                :threshold="1"
                :once="true"
                @on-change="onChange"
              >
                <div class="flex-1 text-center mb-8 md:mb-0">
                  <h2
                    class="uppercase text-blue-700 font-bold text-[100px] drop-shadow-lg"
                  >
                    {{ animatedNumber }}
                  </h2>
                  <p>
                    FOOTBALL GAMES CORRECTLY<br />
                    PREDICTED AND COUNTING
                  </p>
                </div>
              </vee-observe>
            </div>
          </section>
        </div>
      </div>
      <div class="bg-white">
        <section class="max-w-4xl mx-auto px-4 py-16 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-grid">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 md:text-right">
                <h2
                  class="uppercase text-gray-800 mb-2 font-bold text-3xl drop-shadow-lg"
                >
                  Premier League, Champions League, Farmers Leagues...
                </h2>
                <p>
                  Subscribe to numbers_game and gain insight beyond the casual
                  punter in La Liga, Serie A, Bundesliga and many more leagues.
                </p>
                <div class="hidden md:block space-x-4 mt-4">
                  <router-link
                    :to="`/signup/${type}`"
                    class="inline-flex bg-blue-700 items-center justify-center border border-blue-700 text-xs p-3 px-6 font-bold uppercase lg:text-lg text-white"
                  >
                    Get Started
                  </router-link>
                  <!-- <router-link
                  to="/why"
                  class="inline-flex bg-white underline items-center justify-center border border-brandGrey text-xs p-3 px-6 font-bold uppercase lg:text-lg text-blue-700"
                >
                  Watch Demo
                </router-link> -->
                </div>
              </div>
              <div class="mt-4 md:mt-0relative">
                <div
                  class="md:min-w-[340px] max-w-xl lg:max-w-none flex-1 bg-white border  mx-auto border-black text-center  relative"
                >
                  <h2 class="bg-brandGrey/20 py-4 mb-4">Analyst</h2>
                  <span
                    class="bg-green-400 text-xs rounded p-2 absolute -top-2 -right-2"
                    >Most Popular!</span
                  >

                  <p class="text-center uppercase">
                    Unlimited access to:
                  </p>
                  <LeagueList class="mt-4"></LeagueList>
                  <div class="border-t border-brandGrey/50 mx-4 pt-4 mt-4 pb-4">
                    <router-link
                      :to="`/signup/${type}`"
                      class="relative inline-flex bg-white underline items-center justify-center border border-brandGrey text-xs p-3 px-6 font-bold uppercase lg:text-lg text-blue-700"
                    >
                      Get Started
                      <div
                        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                      ></div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <div class="">
          <section class="max-w-4xl mx-auto px-4 py-16 relative">
            <div class="">
              <img src="@/assets/introducing-title.png" />
            </div>
          </section>

          <div class="relative z-10 flex items-center justify-center">
            <iframe
              class="w-half h-half"
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/zSVFHuZhXcw"
              title="Introducing Numbers Game"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <!-- <div class="bg-white" style="padding-left: 2%; padding-right: 2%;">
        <section class="max-w-4xl mx-auto px-4 py-6 lg:py-16 relative">
          <h1 class="uppercase text-gray-800 font-bold text-2xl">
            CHECK OUT SOME OF OUR PREVIOUS RESULTS
          </h1>
        </section>

        <div class="flex flex-wrap">
          <InstagramPost postID="CoIQV0xoAPa" postImage="CoIQV0xoAPa.jpg" />
          <InstagramPost postID="CjlBahUI1OS" postImage="CjlBahUI1OS.jpeg" />
          <InstagramPost postID="CrDRdP3o244" postImage="CrDRdP3o244.jpg" />
          <InstagramPost postID="CdTheeWI_mB" postImage="CdTheeWI_mB.jpeg" />
          <InstagramPost postID="CcKr5hFInwo" postImage="CcKr5hFInwo.jpg" />
          <InstagramPost postID="CbiATB-I5SD" postImage="CbiATB-I5SD.jpeg" />
          <InstagramPost postID="CbNmrMdInkp" postImage="CbNmrMdInkp.jpeg" />
          <InstagramPost postID="CaQf731oTPL" postImage="CaQf731oTPL.jpeg" />
        </div>
      </div> -->
    </template>
    <template v-else>
      <div class="my-16 flex items-center justify-center">
        <Loader></Loader>
      </div>
    </template>
  </div>
</template>
<script>
// import Arrow from "@/assets/icons/Arrow";
import Card from "@/components/Card";
import Loader from "@/components/Loader";
import LeagueList from "@/components/LeagueList";
// import InstagramPost from "@/components/InstagramPost";
import VeeObserve from "vee-observe";

import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import gsap from "gsap";

export default {
  name: "Intro",
  props: ["nextMatch", "nextMatchTime", "totalCorrectMatches"],
  components: {
    // Arrow,
    Card,
    Loader,
    LeagueList,
    VeeObserve,
    // InstagramPost,
  },

  data() {
    return {
      type: "monthly",
      scrolling: false,
      countdown: false,
      countdownInterval: false,
      number: 0,
      tweenedNumber: 0,
    };
  },
  methods: {
    toggleLogin() {
      document.getElementById("loginbutton").click();
    },
    onChange(entry) {
      if (entry.isIntersecting) {
        this.number = this.totalCorrectMatches;
      }
    },
    pad(num) {
      return num > 9 ? num : "0" + num;
    },
    generateCountdown() {
      let remaining =
        dayjs(this.nextMatchTime * 1000).valueOf() - dayjs().valueOf();

      let days = Math.floor(remaining / (1000 * 60 * 60 * 24)),
        hours = Math.floor(remaining / (1000 * 60 * 60)),
        mins = Math.floor(remaining / (1000 * 60)),
        secs = Math.floor(remaining / 1000),
        dd = days,
        hh = hours - days * 24,
        mm = mins - hours * 60,
        ss = secs - mins * 60;

      const returnString = `${this.pad(dd)}:${this.pad(hh)}:${this.pad(
        mm
      )}:${this.pad(ss)}`;
      this.countdown = returnString;
    },
  },
  computed: {
    animatedNumber: function() {
      return this.tweenedNumber.toFixed(0);
    },
  },
  watch: {
    number: function(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
    },
    nextMatchTime() {
      if (this.nextMatchTime) {
        this.countdownInterval = setInterval(() => {
          this.generateCountdown();
        }, 1000);
      }
    },
    countdown() {
      if (
        this.countdown == "00:00:00:00" ||
        this.countdown.charAt(0) == "-" ||
        this.countdown.charAt(1) == "-"
      ) {
        clearInterval(this.countdownInterval);
        this.countdown = "starting now!";
        return;
      }
    },
  },
  mounted() {
    this.$refs.video ? this.$refs.video.pause() : false;

    // Listen for scroll events
    window.addEventListener(
      "scroll",
      () => {
        // Clear our timeout throughout the scroll
        window.clearTimeout(this.scrolling);
        this.$refs.video ? this.$refs.video.play() : false;

        // Set a timeout to run after scrolling ends
        this.scrolling = setTimeout(() => {
          // Run the callback
          this.$refs.video ? this.$refs.video.pause() : false;
          console.log("Scrolling has stopped.");
        }, 66);
      },
      false
    );
    var text = document.getElementById("typewriter-header");

    // eslint-disable-next-line no-undef
    var typewriter = new Typewriter(text, {
      loop: true,
      delay: 50,
    });

    typewriter
      .typeString("Predict football matches.")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(33)
      .typeString("Win FPL.")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(33)
      .typeString("Get ahead of the game.")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(33)
      .start();
  },
};
</script>
